import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import '@/assets/scss/tailwind.scss'
import '@/assets/libs/@mdi/font/css/materialdesignicons.min.css'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import axios from '@/plugins/axios';
import { getSetting } from './assets/libs/settingsService'
import store from './store';
import 'maz-ui/styles'

//Sweetalert
import Swal from "sweetalert2";
window.Swal = Swal;

// loader
import {LoadingPlugin} from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

const favicon = getSetting('favicon_front');
const link = document.querySelector("link[rel~='icon']");
if (link) {
  link.href = favicon;
} else {
  const newLink = document.createElement('link');
  newLink.rel = 'icon';
  newLink.href = favicon;
  document.head.appendChild(newLink);
}

// Cambiar el título
const title = getSetting('title_front');
document.title = title;

 const app = createApp(App)
 app.config.globalProperties.$axios = axios; // Hace que Axios esté disponible globalmente
 app.use(store)
 app.use(VueDatePicker)
 app.use(router)
 app.use(LoadingPlugin)
 app.mount('#app')
