import axios from 'axios';
import authHeader from '../services/auth.header';

const user = JSON.parse(localStorage.getItem('user'));
const profile = JSON.parse(localStorage.getItem('profile'));
const token = localStorage.getItem('access_token');

const initialState = user
    ? { status: { loggedIn: true }, user: user, profile: profile, token }
    : { status: { loggedIn: false }, user: null, profile: null, token: null };

const handleSuccess = (state, user) => {
    state.auth.status.loggedIn = true;
    state.user = user.user;
    state.profile = user.profile;
    state.token = user.access_token;
};

const resetState = (state) => {
    state.auth.status.loggedIn = false;
    state.user = null;
    state.token = null;
};

export const auth = {
    namespaced: true,
    state: initialState,
    actions: {
        // login({ commit }, user) {
        //     commit('loginSuccess');
        //     window.location.href = '/';
        // },
        logout({ commit }) {
            axios.get('/api/logout', { headers: authHeader() })
            .then(() => {
                localStorage.removeItem('user');
                localStorage.removeItem('profile');
                localStorage.removeItem('access_token');
                localStorage.removeItem('token_type');
                commit('logout');
                window.location.href = '/';
            }).catch(e => {
                const { message } = e.response.data;
                localStorage.removeItem('user');
                localStorage.removeItem('profile');
                localStorage.removeItem('access_token');
                localStorage.removeItem('token_type');
                commit('logout');
                console.log(message);
            });
        },
    },
    mutations: {
        loginSuccess(state, user) {
            handleSuccess(state, user);
        },
        loginFailure(state) {
            resetState(state);
        },
        registerSuccess(state, user) {
            handleSuccess(state, user);
        },
        registerFailure(state) {
            resetState(state);
        },
        logout(state) {
            resetState(state);
        }
    }
};
