import settings from '@/assets/settings.json';

let s3Url = process.env.VUE_APP_S3_URL;
if (!s3Url.endsWith("/")) {
  s3Url = s3Url + "/";
}

export const getSettings = () => {
  return settings;
};

export const getSetting = (key) => {
  let value = settings.find(setting => setting.name === key)?.value ?? null;
  if (value === null) {
    console.error('Setting not found:', key);
    return null;
  }
  if (value.startsWith('houxe')) {
    return s3Url + value;
  }
  if (value.startsWith('[houxe')) {
    const images = value.replace('[', '').replace(']', '').split(',');
    return images.map(image => s3Url + image.trim());
  }
  return value;
}
