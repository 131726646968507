// store/index.js
import { createStore } from 'vuex';
import { auth } from "./auth.module";
import axios from 'axios';
import { getSetting } from '@/assets/libs/settingsService';

import {useLoading} from 'vue-loading-overlay'
let loader = null;
const $loading_overlay = useLoading({
    color: '#12b7bd',
});

export default createStore({
    state: {
        company: {
            name: getSetting('title_front'),
        },
        loading: {} // Inicializar el estado loading
    },
    mutations: {
        setUser(state, user) {
            state.auth.status.loggedIn = true;
            state.user = user;
            state.token = user.token;
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('profile', JSON.stringify(user.profile));
            localStorage.setItem('access_token', user.token);
            localStorage.setItem('token_type', user.type);
            if(user.photo){
                localStorage.setItem('photo', user.photo);
            }
        },
        logoutUser() {
            axios.get('/api/front/logout')
            .then(response => {
                console.log(response.data);
                localStorage.removeItem('user');
                localStorage.removeItem('profile');
                localStorage.removeItem('access_token');
                localStorage.removeItem('token_type');
                localStorage.removeItem('photo');
                window.location.href = '/';
            }).catch(e => {
                const { message } = e.response.data;
                console.log(message);
                localStorage.removeItem('user');
                localStorage.removeItem('profile');
                localStorage.removeItem('access_token');
                localStorage.removeItem('token_type');
                localStorage.removeItem('photo');
                window.location.href = '/';
            });
        },
        getProfile(state) {
            axios.get('/api/front/get-profile')
            .then(response => {
                localStorage.removeItem('profile');
                localStorage.setItem('profile', JSON.stringify(response.data.profile));
                state.auth.profile = response.data.profile;
            }).catch(e => {
                const { message } = e.response.data;
                console.log(message);
            });
        },
        updateUser(state, user) {
            console.log('updateUser', user);
            state.auth.user = user;
            localStorage.setItem('user', JSON.stringify(state.auth.user));
            localStorage.setItem('profile', JSON.stringify(user.profile));
        },
        setLoading(state, { url, isLoading }) {
            // Actualizar el estado directamente
            state.loading = {
                ...state.loading,
                [url]: isLoading
            };

            // si hay alguna carga en curso, mostrar el overlay
            let loading = false;
            for (const key in state.loading) {
                if (state.loading[key]) {
                    loading = true;
                    break;
                }
            }
            if (loading) {
                if (loader == null) {
                    loader = $loading_overlay.show();
                }
            }else{
                if (loader != null) {
                    loader.hide();
                }
                loader = null;
            }
        }
    },
    actions: {
      setLoading({ commit }, payload) {
        commit('setLoading', payload);
      }
    },
    getters: {
        getUser(state) {
            return state.auth.user;
        },
        getProfile(state) {
            return state.auth.profile;
        },
        isAuthenticated(state) {
            return state.auth.status.loggedIn;
        },
        isLoading: (state) => (url) => {
          return state.loading[url] || false;
        }
    },
    modules: {
        auth
    },
});