import { createWebHistory, createRouter } from "vue-router";
import store from '../store';

const routes = [
  {
    path: "/",
    name: "index",
    component: () => import('@/pages/index/index.vue'),
  },
  {
    path: "/:catchAll(.*)",
    name: "404",
    component: () => import('@/pages/innerpages/special-pages/404.vue'),
  },
  {
    path: "/login",
    name: "login",
    component: () => import('@/pages/innerpages/auth-pages/login.vue'),
  },
  {
    path: "/auth/forgot-password",
    name: "forgot-password",
    component: () => import('@/pages/innerpages/auth-pages/forgot-password.vue'),
  },
  {//reset-password
    path: "/auth/reset-password",
    name: "reset-password",
    component: () => import('@/pages/innerpages/auth-pages/reset-password.vue'),
  },
  {
    path: "/user/profile",
    name: "user-profile",
    component: () => import('@/pages/innerpages/my-account/user-account.vue'),
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import('@/pages/innerpages/contact.vue'),
  },
  {
    path: "/user/setting",
    name: "user-setting",
    component: () => import('@/pages/innerpages/my-account/user-setting.vue'),
  },
  {
    path: "/user/visitor/list",
    name: "visitor-list",
    component: () => import('@/pages/innerpages/visitors/visitor-list.vue'),
  },
  {
    path: "/user/invoice/list",
    name: "invoice-list",
    component: () => import('@/pages/innerpages/invoices/invoice-list.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: "/user/invoice/:code",
    name: "invoices.show",
    component: () => import('@/pages/innerpages/invoices/show.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: "/invoice/:code",
    name: "invoices.share",
    component: () => import('@/pages/innerpages/invoices/invoice-share.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: "/user/invoice/:code/landing",
    name: "user.invoice.landing",
    component: () => import('@/pages/innerpages/invoices/landing.vue'),
  },
  {
    path: "/invoice/:code/landing",
    name: "invoice.landing",
    component: () => import('@/pages/innerpages/invoices/landing-share.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});


router.beforeEach(async (to, from, next) => {

  if (to.meta && to.meta.requiresAuth) {
    if (!store.getters.isAuthenticated) {
      next({
        name: 'login',
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;